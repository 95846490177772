@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {

  html {
    scroll-behavior: smooth;
  }
  
  ::selection {
    @apply text-emerald-900 bg-emerald-200;
  }

  @font-face {
    font-family: 'Freebly';
    font-weight: 600;
    src: url('../fonts/Freebly-SemiBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Freebly';
    font-weight: 700;
    src: url('../fonts/Freebly-Bold.woff2') format('woff2');
  }

  @font-face {
    font-family: SegoeUI;
    font-weight: 100;
    src:
      local("Segoe UI Light"),
      url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
  }

  @font-face {
      font-family: SegoeUI;
      font-weight: 200;
      src:
        local("Segoe UI Semilight"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
  }

  @font-face {
      font-family: SegoeUI;
      font-weight: 400;
      src:
        local("Segoe UI"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
  }
  
    @font-face {
        font-family: SegoeUI;
        font-weight: 600;
        src:
          local("Segoe UI Semibold"),
          url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
    }
  }

  @font-face {
      font-family: SegoeUI;
      font-weight: 700;
      src:
        local("Segoe UI Bold"),
        url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
  }

@layer components {
  .font-display {
    font-family: 'Freebly', 'Inter', 'SegoeUI', 'Helvetica', 'Arial', 'sans-serif', 'system-ui';
  }

  .compensate-for-header {
    @apply mb-0 pt-32 -mt-32;
  }

  .link-text {
    @apply transition-all duration-75 font-semibold text-emerald-500 hover:text-emerald-400 border-emerald-400 hover:border-b;
  }

  .link-text-alt {
    @apply transition-all duration-100 py-0.5 border-b-2 border-emerald-300 hover:text-emerald-500;
  }

  .flair-xs {
    @apply text-xs px-2 py-1 rounded-lg font-medium text-emerald-600 bg-emerald-100;
  }

  .flair-xs-alt {
    @apply text-xs px-1 py-0.5 rounded-md font-semibold text-emerald-600 bg-emerald-100;
  }

  .flair-sm {
    @apply text-sm px-1.5 py-0.5 rounded-md font-semibold text-emerald-600 bg-emerald-100;
  }
}

@layer utilities {
  .transition-default {
    @apply transition-all duration-150;
  }
}